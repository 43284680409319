<template>
  <div>
    <b-table
      responsive
      :fields="fields"
      :items="dataList"
      striped
      small
    >
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <div class="mr-1">
            <b-button
              variant="flat-info"
              size="sm"
              pill
              class="btn-icon"
              @click="setRequestModal(data.item)"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
          <b-link :to="'/crm/view/' + data.item.id">
            <div class="text-body">
              {{ data.item.name }}
            </div>
            <div class="text-warning">
              <small>{{ data.item.company }}</small>
            </div>
          </b-link>
        </div>
      </template>
      <template #cell(brand)="data">
        <div>{{ data.item.brand }}</div>
        <div class="text-primary">
          <small>{{ data.item.department }}</small>
        </div>
      </template>
      <template #cell(openDays)="data">
        <div class="text-center">
          <div
            v-if="data.item.openDays > 0"
            class="text-danger"
          >
            <small>{{ data.item.openDays }}</small>
          </div>
          <div
            v-else
            class="text-muted"
          >
            <small>{{ data.item.openDays }}</small>
          </div>
        </div>
      </template>
      <template #cell(control)="data">
        <b-dropdown
          v-if="allowedUpdate.includes(userData.id_com_user_type)"
          :split-to="'/crm/view/' + data.item.id"
          split
          block
          size="sm"
          text="Görüntüle"
          variant="outline-primary"
        >
          <b-dropdown-item
            :to="'/crm/edit/' + data.item.id"
          >
            Güncelle
          </b-dropdown-item>
          <b-dropdown-item
            v-if="userData.id_com_user_type === '7'"
            @click="deleteData(data.item.id)"
          >
            Sil
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          v-else
          :to="'/crm/view/' + data.item.id"
          variant="outline-primary"
          block
          size="sm"
        >
          Görüntüle
        </b-button>
      </template>
      <template #cell(id)="data">
        <div class="text-muted text-right">
          <small>{{ data.item.id }}</small>
        </div>
      </template>
    </b-table>
    <preview-modal />
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BLink,
} from 'bootstrap-vue'
import PreviewModal from '@/views/Crm/components/PreviewModal.vue'

export default {
  name: 'Table',
  components: {
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BLink,
    PreviewModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      allowedUpdate: ['1', '7', '8'],
      fields: [
        {
          key: 'name',
          label: 'Müşteri',
          thClass: 'font-small-3 align-middle',
          tdClass: 'font-small-3 text-ellipsis',
        },
        {
          key: 'brand',
          label: 'Marka Departman',
          thClass: 'width-100 font-small-3 align-middle',
          tdClass: 'width-100 text-nowrap font-small-3',
        },
        {
          key: 'car_license_plate',
          label: 'Plaka',
          thClass: 'width-100 text-left font-small-3 align-middle',
          tdClass: 'width-100 text-nowrap font-small-3',
        },
        {
          key: 'crm_status',
          label: 'Durum',
          thClass: 'width-100 text-left font-small-3 align-middle',
          tdClass: 'width-100 text-nowrap font-small-3',
        },
        {
          key: 'username',
          label: 'Açan Kullanıcı',
          thClass: 'width-100 text-left font-small-3 align-middle',
          tdClass: 'width-100 text-nowrap font-small-3',
        },
        {
          key: 'username1',
          label: 'Yetkili',
          thClass: 'width-100 text-left font-small-3 align-middle',
          tdClass: 'width-100 text-nowrap font-small-3',
        },
        {
          key: 'openDays',
          label: 'Gün',
          thClass: 'width-50 text-left font-small-3 align-middle',
          tdClass: 'width-50 text-nowrap font-small-3',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thClass: 'width-50 text-left font-small-3 align-middle',
          tdClass: 'width-100 text-nowrap font-small-3',
        },
        {
          key: 'id',
          label: 'NO',
          thStyle: { width: '20px', textAlign: 'right', verticalAlign: 'middle' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['crmRequests/getRequests']
    },
    saveStatus() {
      return this.$store.getters['crmRequests/getRequestSaveStatus']
    },
    requestModal() {
      return this.$store.getters['crmRequests/requestModal']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.resetModal()
  },
  methods: {
    resetModal() {
      this.requestModal.status = false
      this.requestModal.data = {}
    },
    setRequestModal(data) {
      this.$store.dispatch('crmRequests/requestModalItem', data.id)
        .then(response => {
          if (response) {
            this.requestModal.status = true
          }
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    deleteData(id) {
      this.$store.dispatch('crmRequests/requestDelete', id)
    },
  },
}
</script>
